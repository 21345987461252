<template>
  <div v-if="!loading">
    <b-alert
      v-if="dataItem"
      variant="danger"
      show
    >
      <div class="alert-body text-center py-2">
        <FeatherIcon
          icon="InfoIcon"
          size="24"
        />
        <div class="font-weight-bold font-medium-3">
          Uyarı!
        </div>
        <div class="lead">
          Müşteri için çözümlenmemiş başvuru bulunmaktadır.!
        </div>
        <div
          class="mt-1"
        >
          <b-button
            variant="outline-danger"
            target="_blank"
            :to="'/crm/view/' + dataItem.id"
          >
            Başvuruyu Görüntüle
          </b-button>
        </div>
      </div>
    </b-alert>
  </div>
</template>
<script>
import { BAlert, BButton } from 'bootstrap-vue'

export default {
  name: 'CustomerControl',
  components: {
    BAlert,
    BButton,
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['crmRequests/getRequestItem']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('crmRequests/requestItem', {
        select: [
          'com_crm.id AS id',
          'com_crm.id_com_customer AS id_com_customer',
        ],
        where: {
          'com_crm.id_com_customer': this.$route.params.id,
          'com_crm.id_com_crm_status !=': 3,
        },
        array: false,
      })
        .then(() => {
          this.loading = false
        })
    },
  },
}
</script>
