<template>
  <div>
    <b-card
      title="E-Posta Bilgilendirmesi"
    >
      <b-list-group>
        <b-list-group-item
          v-for="(email,key) in emailData"
          :key="key"
        >
          <b-row>
            <b-col>
              <div class="font-small-3">
                {{ email.name }}
              </div>
              <div class="font-small-1 text-primary">
                {{ email.email }}
              </div>
            </b-col>
            <b-col cols="auto">
              <b-button
                variant="flat-danger"
                class="btn-icon"
                pill
                size="sm"
                @click="removeEmail(key)"
              >
                <FeatherIcon icon="XIcon" />
              </b-button>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
      <b-alert
        show
        variant="info"
        class="mt-1"
      >
        <div class="alert-body text-center">
          E-posta adreslerine bilgilendirme yapılacaktır.
        </div>
      </b-alert>
      <hr>
      <b-form-group
        label="Kullanıcı Tanımla"
        label-for="addCC"
      >
        <v-select
          id="addCC"
          v-model="addCC"
          :options="users"
          label="name"
          :reduce="user => user.id"
          placeholder="Seçiniz"
        />
      </b-form-group>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem, BRow, BCol, BButton, BAlert, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'EMails',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BButton,
    BAlert,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      addCC: null,
      ccUsers: [],
      formData: {
        id_com_location: null,
        id_com_brand: null,
        id_com_department: [],
        id_com_user: [],
      },
    }
  },
  computed: {
    crmRequest() {
      return this.$store.getters['crmRequests/getRequest']
    },
    emailData() {
      return this.$store.getters['crmRequests/getRequestEMails']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
  },
  watch: {
    addCC(val) {
      if (val) {
        this.ccUsers.push(val)
        this.formData.id_com_user.push(val)
        this.getEmails()
        this.addCC = null
      }
    },
    formData: {
      deep: true,
      handler(val) {
        if (val.id_com_location && val.id_com_brand && val.id_com_department) {
          this.getEmails()
        }
      },
    },
    crmRequest: {
      deep: true,
      handler(val) {
        if (val.id_com_location) {
          this.formData.id_com_location = val.id_com_location
        }
        if (val.id_com_brand) {
          this.formData.id_com_brand = val.id_com_brand
        }
        if (val.id_com_department1 || val.id_com_department2) {
          const departments = []
          if (val.id_com_department1) {
            departments.push(val.id_com_department1)
          }
          if (val.id_com_department2) {
            departments.push(val.id_com_department2)
          }
          this.formData.id_com_department = departments
        }
        if (val.id_com_user1 || val.id_com_user2 || val.id_com_user3 || val.id_com_confirmation_user) {
          const users = []
          if (val.id_com_user1) {
            users.push(val.id_com_user1)
          }
          if (val.id_com_user2) {
            users.push(val.id_com_user2)
          }
          if (val.id_com_user3) {
            users.push(val.id_com_user3)
          }
          if (val.id_com_confirmation_user) {
            users.push(val.id_com_confirmation_user)
          }
          if (this.ccUsers.length > 0) {
            const { ccUsers } = this
            ccUsers.forEach(e => users.push(e))
          }
          this.formData.id_com_user = users
        }
      },
    },
  },
  mounted() {
    this.ccUsers.push(this.userData.id)
  },
  methods: {
    getEmails() {
      this.$store.dispatch('crmRequests/getEmails', this.formData)
    },
    removeEmail(key) {
      const emails = []
      this.emailData.splice(key, 1)
      this.emailData.forEach(e => {
        emails.push(e.id)
      })
      this.crmRequest.email_to = emails
    },
    removeEmail2(key) {
      const emails = []
      this.emailUsers.splice(key, 1)
      this.emailUsers.forEach(e => {
        emails.push(e.id)
      })
      this.crmRequest.email_to = emails
    },
  },
}
</script>

<style scoped>

</style>
