<template>
  <div>
    <b-row>
      <b-col cols="12">
        <customer-control />
      </b-col>
      <b-col
        md="4"
        sm="12"
      >
        <customer-card :id_com_customer="crmRequest.id_com_customer" />
        <car-card
          :id_com_cars="crmRequest.id_com_cars"
        />
        <e-mails />
      </b-col>
      <b-col
        md="8"
        sm="12"
      >
        <addApply :id_com_customer="crmRequest.id_com_customer" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import CustomerCard from '@/views/Crm/components/CustomerCard.vue'
import CarCard from '@/views/Crm/components/CarCard.vue'
import addApply from '@/views/Crm/components/AddApply.vue'
import EMails from '@/views/Crm/components/EMails.vue'
import CustomerControl from '@/views/Crm/components/CustomerControl.vue'

export default {
  name: 'Add',
  components: {
    BRow,
    BCol,
    CustomerCard,
    CarCard,
    addApply,
    EMails,
    CustomerControl,
  },
  computed: {
    crmRequest() {
      return this.$store.getters['crmRequests/getRequest']
    },
  },
  watch: {
    crmRequest(val) {
      if (val) {
        this.crmRequest.id_com_customer = this.$route.params.id
      }
    },
  },
  beforeCreate() {
    this.$store.dispatch('crmRequests/requestReset')
  },
  created() {
    this.$store.commit('cars/RESET_CARS_LIST')
    this.crmRequest.id_com_customer = this.$route.params.id
    this.getCars()
  },
  methods: {
    getCars() {
      this.$store.dispatch('cars/carsList', {
        where: { 'com_cars.id_com_customer': this.$route.params.id },
      })
    },
  },
}
</script>
