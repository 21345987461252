<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-card title="Başvuru Ekle">
        <b-row>
          <b-col md="6">
            <feedbackType />
          </b-col>
          <b-col md="6">
            <applySource />
          </b-col>
          <b-col md="6">
            <locations />
          </b-col>
          <b-col md="6">
            <brands />
          </b-col>
          <b-col md="12">
            <departments />
          </b-col>
          <b-col md="12">
            <b-row>
              <b-col><cars /></b-col>
              <b-col cols="auto">
                <b-button
                  v-if="!crmRequest.id_com_cars"
                  v-b-toggle.sidebar-invoice-add-new-car
                  variant="danger"
                  type="submit"
                  class="mt-2"
                >
                  <FeatherIcon icon="PlusIcon" />
                  Araç Kartı Ekle
                </b-button>
                <b-button
                  v-if="crmRequest.id_com_cars"
                  v-b-toggle.sidebar-invoice-add-new-car
                  variant="warning"
                  type="submit"
                  class="mt-2"
                >
                  <FeatherIcon icon="EditIcon" />
                  Araç Güncelle
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <dateTime />
          </b-col>
          <b-col md="12">
            <importanceLevel />
          </b-col>
          <b-col md="12">
            <applyText />
          </b-col>
          <b-col md="12">
            <subjects />
          </b-col>
          <b-col md="12">
            <users />
          </b-col>
        </b-row>
      </b-card>
      <file-upload />
      <saveButton :action-methods="submitForm" />
    </validation-observer>
    <add-car :refresh-data="getCars" />
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  VBToggle,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import feedbackType from '@/views/Crm/forms/feedbackType.vue'
import applySource from '@/views/Crm/forms/applySource.vue'
import locations from '@/views/Crm/forms/locations.vue'
import brands from '@/views/Crm/forms/brands.vue'
import departments from '@/views/Crm/forms/departments.vue'
import cars from '@/views/Crm/forms/cars.vue'
import dateTime from '@/views/Crm/forms/dateTime.vue'
import importanceLevel from '@/views/Crm/forms/importanceLevel.vue'
import applyText from '@/views/Crm/forms/applyText.vue'
import subjects from '@/views/Crm/forms/subjects.vue'
import users from '@/views/Crm/forms/users.vue'
import saveButton from '@/views/Crm/forms/saveButton.vue'
import AddCar from '@/views/Crm/components/AddCar.vue'
import FileUpload from '@/views/Crm/components/FileUpload.vue'

export default {
  name: 'AddApply',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    ValidationObserver,
    feedbackType,
    applySource,
    locations,
    brands,
    departments,
    cars,
    dateTime,
    importanceLevel,
    applyText,
    subjects,
    users,
    saveButton,
    AddCar,
    FileUpload,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    id_com_customer: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      locale: 'tr',
      required,
    }
  },
  computed: {
    crmRequest() {
      return this.$store.getters['crmRequests/getRequest']
    },
    saveStatus() {
      return this.$store.getters['crmRequests/getRequestSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.$refs.simpleRules.reset()
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.crmRequest.submitStatus = false
      this.$store.commit('crmRequests/REQUEST_RESET', { status: true })
      this.$refs.simpleRules.reset()
      this.$router.push(`/crm/view/${val.id}`)
    },
  },
  beforeCreate() {
    this.$store.dispatch('crmRequests/requestReset')
  },
  created() {
    // this.$refs.simpleRules.reset()
    localize(this.locale)
  },
  methods: {
    getCars() {
      this.$store.dispatch('cars/carsList', {
        where: { 'com_cars.id_com_customer': this.crmRequest.id_com_customer },
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      console.log('aa')
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.crmRequest.id_com_customer = this.$route.params.id
          this.crmRequest.submitStatus = true
          this.$store.dispatch('crmRequests/requestSave', this.crmRequest)
        } else {
          console.log('error')
        }
      }).catch(error => console.error(error))
    },
  },
}
</script>

<style scoped>

</style>
